<template>
    <footer class="mt-10 md:mt-0 bg-gray-700">
        <div class=" h-px"></div>
        <div class="flex flex-wrap-reverse justify-center m-5">
            <a class="flex flex-nowrap  items-center m-3 md:mx-10" href="https://www.facebook.com/csnctu" target="_blank">
                <img class=" w-7 md:mx-1" src="../assets/Footer/facebook.webp">
                <div class="text-sm text-gray-300 px-2">
                    陽交大資工粉絲團 | Facebook
                </div>
            </a>
            <a class="flex flex-nowrap items-center m-3 md:mx-10" href="https://www.facebook.com/nctucs.assoc" target="_blank">
                <img class=" w-7 md:mx-1" src="../assets/Footer/facebook.webp">
                <div class="text-sm text-gray-300 px-2">
                    陽交大資工系學會 | Facebook
                </div>
            </a>
            <a class="flex flex-nowrap items-center m-3 md:mx-10" href="https://medium.com/@csunion.nctu" target="_blank">
                <img class=" w-7 md:mx-1" src="../assets/Footer/medium.webp">
                <div class="text-sm text-gray-300 px-2">
                    你所不資道的事 | Medium
                </div>
            </a>
        </div>
        <hr>
        <div class="m-5">
            <div class=" text-xl m-3">
                <a class="text-gray-900 hover:text-yellow-700" href="https://www.cs.nycu.edu.tw/" target="_blank">國立陽明交通大學資訊工程學系</a> 
            </div>
            <div class="grid grid-rows-2 gap-1 justify-center items-center">
                <div class=" flex flex-nowrap items-center ">
                    <img class="w-5" src="../assets/Footer/phone.webp">
                    <div class="text-gray-800 col-span-2 px-3">03-5712121#56601-12</div>
                </div>
                <div class=" flex flex-nowrap items-center">
                    <img class="w-5" src="../assets/Footer/email.webp">
                    <div class="text-gray-800 col-span-2 px-3">office@cs.nycu.edu.tw</div>
                </div>
            </div>
        </div>
        <div class=" h-px"></div>
    </footer>
</template>